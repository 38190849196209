<template>
  <div>
    <v-card elevation="4">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h5 font-weight-medium">Controladores</div>
        <HelpDialog
          class="mx-4 flex-grow-1"
          title="Controladores"
          message="Preciso de ajuda para cadastrar controladores."
        >
          <p>
            Controladores são pessoas naturais ou jurídicas responsáveis pelas decisões relacionadas ao tratamento de dados.
          </p>
          <p>
            
          </p>
          <v-expansion-panels class="my-2" ripple>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Definição do Controlador (LGPD Art. 5, inciso VI)
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  VI - controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Compartilhamento de Dados com outros Controladores (LGPD Art. 7, §5º)
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  § 5º O controlador que obteve o consentimento referido no inciso I do caput deste artigo que necessitar comunicar ou compartilhar dados pessoais com outros controladores deverá obter consentimento específico do titular para esse fim, ressalvadas as hipóteses de dispensa do consentimento previstas nesta Lei.
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Dever do Controlador (LGPD Art. 8, §2º)
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  § 2º Cabe ao controlador o ônus da prova de que o consentimento foi obtido em conformidade com o disposto nesta Lei.
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </HelpDialog>
        <v-btn color="success" @click="openControllerDialog()">
          Novo Controlador
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details="auto"
          class="mt-0 pt-0"
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="controllers"
        :items-per-page="-1"
        :search="search"
        :loading="loading"
        loading-text="Carregando... Por favor, espere"
        hide-default-footer
        no-data-text="Nenhum controlador cadastrado"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="openControllerDialog(item)">
            <v-icon class="mr-2"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="
              deleteDialog = true;
              controller_id = item.id;
            "
          >
            <v-icon class="mr-2"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <v-dialog
      v-model="controllerDialog"
      max-width="800"
      :persistent="loadingSave"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div v-if="controllerForm.id">Edição de Controlador</div>
          <div v-else>Novo Controlador</div>
          <div>
            <v-btn
              icon
              @click="controllerDialog = false"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <v-form ref="form" @submit.prevent="saveController()">
            <v-text-field
              v-model="controllerForm.name"
              label="Nome Completo / Razão Social *"
              outlined
              hide-details="auto"
              color="black"
              class="my-3"
              :rules="[$rules.required]"
              :disabled="loadingSave"
            />
            <v-row no-gutters>
              <v-text-field
                v-model="controllerForm.email"
                label="E-Mail *"
                outlined
                hide-details="auto"
                color="black"
                class="my-3 mr-1"
                :rules="[$rules.required, $rules.email]"
                :disabled="loadingSave"
              />
              <v-text-field
                v-model="controllerForm.phone"
                label="Telefone *"
                outlined
                hide-details="auto"
                color="black"
                class="my-3 ml-1"
                placeholder="(00) 00000-0000"
                v-mask="['(##) ####-####', '(##) #####-####']"
                :rules="[$rules.required, $rules.phone]"
                :disabled="loadingSave"
              />
            </v-row>
            <v-card-actions class="pt-3 pb-0 pr-0">
              <v-spacer/>
              <v-btn
                color="secondary"
                class="mr-1"
                @click="controllerDialog = false"
                :disabled="loadingSave"
                text
              >
                Cancelar
              </v-btn>
              <v-btn
                color="success"
                type="submit"
                :loading="loadingSave"
              >
                <template v-if="controllerForm.id">
                  Salvar
                </template>
                <template v-else>
                  Criar
                </template>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500"
      :persistent="loadingDelete"
    >
      <v-card color="grey lighten-2">
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir esse Controlador?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            class="mr-1"
            text
            :disabled="loadingDelete"
            @click="deleteDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            class="ml-1"
            :loading="loadingDelete"
            color="error"
            text
            @click="deleteController()"
            >Excluir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";

export default {
  name: "Controllers",

  components: { HelpDialog, },

  data: () => ({
    search: "",
    loading: false,
    loadingDelete: false,
    controllerDialog: false,
    controllerDialog: false,
    deleteDialog: false,
    loadingSave: false,
    controllerForm: {
      name: "",
      email: "",
      phone: "",
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Telefone", value: "phone" },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    controllers: [],
  }),
  mounted() {
    this.getControllers();
  },
  methods: {
    saveController() {
      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        let request;
        if (this.controllerForm.id) {
          request = this.$axios.put(
            `/controller/${this.controllerForm.id}`,
            this.controllerForm
          );
        } else {
          request = this.$axios.post("/controller", this.controllerForm);
        }
        request
          .then((response) => {
            this.$showMessage("success", "Controlador Salvo");
            this.getControllers();
            this.controllerDialog = false;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.$showMessage(
                  "warning",
                  "Já Existe um Controlador Com Esse Email"
                );
                return;
              }
              if (error.response.status == 404) {
                this.$showMessage("error", "Controlador não encontrado");
                return;
              }
            }
            this.$showMessage("error", "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    deleteController() {
      this.loadingDelete = true;
      this.$axios
        .delete(`/controller/${this.controller_id}`)
        .then((response) => {
          this.$showMessage("success", "Controlador Excluido");
          this.getControllers();
          this.deleteDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 404) {
              this.$showMessage("error", "Controlador não encontrado");
              return;
            }
          }
          this.$showMessage("error", "Ocorreu um erro inesperado");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    getControllers() {
      this.loading = true;
      this.$axios
        .get("/controllers")
        .then((response) => {
          this.controllers = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.$showMessage(
                "error",
                "Não foi possível carregar seus controladores"
              );
              return;
            }
          }
          this.$showMessage(
            "error",
            "Erro inesperado no carregamento dos controladores"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /* resetForm(){
      this.$refs.form.reset()
    }, */
    openControllerDialog(controller) {
      if (controller) {
        this.controllerForm = Object.assign({}, controller);
      } else {
        this.controllerForm = {};
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
      this.controllerDialog = true;
    },
  },
};
</script>